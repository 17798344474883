import moment from 'moment-timezone';
export default {
    data(){
        return{
            
        }
    },
    methods: {
        moment(date) {
            return moment(date);
        },
        convertTime24To12(time) {
            // Check correct time format and split into components
            time = time.toString().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
            
            if (time.length > 1) { // If time format correct
                time = time.slice (1);  // Remove full string match value
                time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
                time[0] = +time[0] % 12 || 12; // Adjust hours
            }
            time[0] = String(time[0]).length < 2 ? '0'+time[0] : time[0]
            return time.join (''); // return adjusted time or original string
        },
        convertTime12to24(time12h) {
            var time = time12h;
            var hours = Number(time.match(/^(\d+)/)[1]);
            var minutes = Number(time.match(/:(\d+)/)[1]);
            var AMPM = time.match(/\s(.*)$/)[1];
            if(AMPM == "PM" && hours<12) hours = hours+12;
            if(AMPM == "AM" && hours==12) hours = hours-12;
            var sHours = hours.toString();
            var sMinutes = minutes.toString();
            if(hours<10) sHours = "0" + sHours;
            if(minutes<10) sMinutes = "0" + sMinutes;
            return sHours + ":" + sMinutes + ":" + "00";
        },
        convertTime12to24WithoutSec(time12h) {
            var time = time12h;
            var hours = Number(time.match(/^(\d+)/)[1]);
            var minutes = Number(time.match(/:(\d+)/)[1]);
            var AMPM = time.match(/\s(.*)$/)[1];
            if((AMPM == "PM" || AMPM == "pm") && hours<12) hours = hours+12;
            if((AMPM == "AM" || AMPM == "am") && hours==12) hours = hours-12;
            var sHours = hours.toString();
            var sMinutes = minutes.toString();
            if(hours<10) sHours = "0" + sHours;
            if(minutes<10) sMinutes = "0" + sMinutes;
            return sHours + ":" + sMinutes;
        },
        getMinutesFrom12H(time12h) {
            var time = time12h;
            var hours = Number(time.match(/^(\d+)/)[1]);
            var minutes = Number(time.match(/:(\d+)/)[1]);
            var AMPM = time.match(/\s(.*)$/)[1];
            if((AMPM == "PM" || AMPM == "pm") && hours<12) hours = hours+12;
            if((AMPM == "AM" || AMPM == "am") && hours==12) hours = hours-12;
            var sHours = hours.toString();
            var sMinutes = minutes.toString();
            if(hours<10) sHours = "0" + sHours;
            if(minutes<10) sMinutes = "0" + sMinutes;
            return (sHours * 60) + parseInt(sMinutes);
        },
        convertHMS(value) {
            const sec = parseInt(value, 10); // convert value to number if it's string
            let hours   = Math.floor(sec / 3600); // get hours
            let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
            let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
            // add 0 if value < 10; Example: 2 => 02
            if (hours   < 10) {hours   = hours;}
            if (minutes < 10) {minutes = "0"+minutes;}
            if (seconds < 10) {seconds = "0"+seconds;}
            return hours+'.'+minutes; // Return is HH : MM : SS
        },
        toDateWithOutTimeZone(date, time) {
            let tempTime = time.split(":");
            let dt = new Date(date);
            dt.setHours(tempTime[0]);
            dt.setMinutes(tempTime[1]);
            dt.setSeconds(tempTime[2]);
            return dt;
        },
        convertHMSFromDateAndTime(start_date, end_date, start_time, end_time) {
            let current_start_date = new Date();
            let current_end_date = new Date();
            if(start_time.includes('AM') && end_time.includes('AM')) {
                let start_minutesss = this.getMinutesFrom12H(start_time);
                let end_minutesss = this.getMinutesFrom12H(end_time);
                if(end_minutesss < start_minutesss) {
                    current_end_date.setDate(current_end_date.getDate() + 1)
                }
            } 
            if(start_time.includes('PM') && end_time.includes('PM')) {
                let start_minutesss = this.getMinutesFrom12H(start_time);
                let end_minutesss = this.getMinutesFrom12H(end_time);
                if(end_minutesss < start_minutesss) {
                    current_end_date.setDate(current_end_date.getDate() + 1)
                }
            }
            if(start_time.includes('PM') && end_time.includes('AM')) {
                current_end_date.setDate(current_end_date.getDate() + 1)
            }
            start_time = start_time ? this.convertTime12to24WithoutSec(start_time) : '';
            end_time = end_time ? this.convertTime12to24WithoutSec(end_time) : '';
            start_date = start_date ? start_date : this.moment(current_start_date).format('DD/MM/YYYY');
            end_date = end_date ? end_date : this.moment(current_end_date).format('DD/MM/YYYY');
            let new_start_date = start_date.split('/')[1]+'/'+start_date.split('/')[0]+'/'+start_date.split('/')[2];
            let new_end_date = end_date.split('/')[1]+'/'+end_date.split('/')[0]+'/'+end_date.split('/')[2];
            let final_start_time = start_time ? start_time+':00' : '00:00:00';
            let final_end_time = end_time ? end_time+':00' : '00:00:00';
            let a_f = this.toDateWithOutTimeZone(new_start_date, final_start_time);
            let b_f = this.toDateWithOutTimeZone(new_end_date, final_end_time);
            let date1 = new Date(a_f);
            let date2 = new Date(b_f);
            let Difference_In_Time = date2.getTime() - date1.getTime();
            Difference_In_Time = Difference_In_Time / 1000;
            let correct_time = this.convertHMS(Difference_In_Time);
            return correct_time;
        }
    }
}